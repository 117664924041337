.banner {
  background: url("../images/banner/banner.jpg") center center no-repeat;
  background-size: cover;
  overflow: hidden;

  .banner-content {
    padding: 250px 0px 220px 0px;

    h2 {
      font-size: 100px;
      line-height: 100px;
    }

    p {
      font-size: 17px;
    }
  }

  .banner-img {
    position: absolute;
    content: "";
    bottom: 0px;
    right: 17%;
    @media(max-width:1400px){
      right: 10%;
    }

    @include desktop-lg {
      right: -10%;
    }
  }
}

// Banner-2 Style

.banner-2 {
  overflow: hidden;
  padding-top: 50px;

  .banner-content-2 {
    padding: 180px 0px 120px 0px;
    @include desktop-lg {
      padding: 150px 0 100px 0;
    }

    h2 {
      font-size: 60px;
      line-height: 70px;
      @include desktop-lg {
        font-size: 50px;
        line-height: 60px;
      }
    }
  }

  p {
    color: rgba(255, 255, 255, .7);
    padding-right: 55px;
    font-size: 17px;
  }

  .btn:hover {
    background: $white;
    color: $black !important;
  }
}


@include mobile-xs {
  .banner .banner-content h2 {
    font-size: 66px;
    line-height: 86px;
  }
}

@include mobile-xs {
  .banner .banner-content h2 {
    font-size: 66px;
    line-height: 86px;
  }
}

@include desktop {
  .banner .banner-content h2 {
    font-size: 85px;
    line-height: 100px;
  }

  .about-banner {
    background-position: 40% 50%;
  }
}