/*!------------------------------------------------------------------
[MAIN STYLESHEET]

PROJECT:	Dizer-Portfolio template
VERSION:	1.0
-------------------------------------------------------------------*/
/*------------------------------------------------------------------
[TABLE OF CONTENTS]
-------------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css?family=Open+Sans|Poppins:400,500,700");
html, body {
  width: 100%;
  height: 100%;
}

html {
  overflow-x: hidden;
}

body {
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
}

p {
  color: #848485;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: "Poppins", sans-serif;
  color: #222530;
  font-weight: 500;
}

h1, .h1 {
  font-size: 45px;
  text-transform: none;
  text-transform: capitalize;
}

h2, .h2 {
  font-size: 42px;
  line-height: 52px;
  text-transform: capitalize;
}

h3, .h3 {
  font-size: 40px;
  line-height: 50px;
}

h4, .h4 {
  font-size: 24px;
  line-height: 30px;
}

h5, .h5 {
  font-size: 20px;
  line-height: 34px;
}

h6, .h6 {
  font-size: 16px;
  line-height: 26px;
}

.text-sm {
  font-size: 14px;
}

.btn {
  padding: 12px 35px;
  border-radius: 3px;
  font-size: 16px;
  display: inline-block;
  font-weight: 500;
  text-transform: capitalize;
  transition: all .3s ease;
  border: 1px solid;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.btn:focus {
  outline: 0px;
  box-shadow: none !important;
}

.btn:active {
  box-shadow: none;
}

.btn-primary {
  background: #f57479;
  color: #fff;
  border-color: #f57479;
}

.btn-primary:hover, .btn-primary:active, .btn-primary.focus, .btn-primary.active {
  background: #1f1f40 !important;
  border-color: #1f1f40 !important;
  color: #fff !important;
}

.btn-secondary {
  background: #1f1f40;
  border-color: #1f1f40;
  color: #fff;
}

.btn-secondary:hover, .btn-secondary:active, .btn-secondary.focus, .btn-secondary.active {
  background: #f57479 !important;
  border-color: #f57479 !important;
  color: #fff !important;
}

.btn-outline-primary {
  border-color: #f57479;
  color: #f57479;
}

.btn-outline-primary:hover, .btn-outline-primary:active, .btn-outline-primary.focus, .btn-outline-primary.active {
  background: #f57479 !important;
  color: #fff !important;
  border-color: #f57479 !important;
}

.btn-solid-border {
  border: 2px solid #e5e5e5;
  color: #000;
}

.btn-solid-border:hover, .btn-solid-border:active, .btn-solid-border.focus, .btn-solid-border.active {
  background: #f57479;
  color: #fff !important;
  border-color: #f57479;
}

.bg-primary {
  background: #f57479 !important;
}

.bg-secondary {
  background: #1f1f40 !important;
}

.bg-extra {
  background: #934ae4;
}

.bg-blue {
  background: #470FA8 !important;
}

.text-color {
  color: #f57479;
}

.text-black {
  color: #000;
}

.section {
  padding: 120px 0px;
}

.section-sm {
  padding: 80px 0px 65px 0px;
}

a {
  transition: all .3s ease;
  -moz-transition: all .3s ease;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
}

a:hover {
  color: #f57479 !important;
}

a:hover,
a:focus {
  outline: 0;
  text-decoration: none;
}

.text-yello {
  color: #ffc429;
}

.text-sm-2 {
  font-size: 18px;
}

.overflow-hidden {
  overflow: hidden !important;
}

.slick-slide {
  outline: 0;
}

.slick-slide img {
  display: inline-block;
}

#main-nav {
  height: 100px;
}

#main-nav .navbar-nav .nav-link {
  text-transform: uppercase;
  color: #fff;
  font-size: 16px;
  padding-right: 20px;
  padding-left: 35px;
  font-weight: 500;
  line-height: 40px;
  font-family: "Poppins", sans-serif;
}

#main-nav .navbar-nav.extra-nav .nav-link {
  padding-left: 30px;
  padding-right: 0px;
}

.navbar-brand {
  font-size: 40px;
  color: #fff;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.dropdown-toggle::after {
  position: absolute;
  content: "\f078";
  font-family: 'Font Awesome 5 Free';
  font-weight: 700;
  border: 0px;
  font-size: 12px;
  margin-left: 10px;
}

.nav-top {
  background: #1f1f40;
  transition: .0s all ease;
  z-index: 99999;
}

.dropdown {
  position: relative;
}

@media (max-width: 991px) {
  .navbar {
    background: #1f1f40;
    z-index: 99999;
  }
}

.navbar .dropdown:hover .dropdown-menu {
  visibility: visible;
  opacity: 1;
  margin-top: 0;
}

.navbar .dropdown-menu {
  box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.12);
  padding: 15px;
  border: 0;
  border-radius: 0;
  background: #fff;
  top: 90%;
  left: 0px;
  margin-top: 20px;
  display: block;
  visibility: hidden;
  opacity: 0;
  right: 0px;
  transition: all .45s ease;
  width: 250px;
}

@media (max-width: 991px) {
  .navbar .dropdown-menu {
    display: none;
    opacity: 1;
    visibility: visible;
    margin-top: 0;
    width: 100%;
  }
}

.navbar .dropdown-menu.show {
  visibility: hidden;
}

@media (max-width: 991px) {
  .navbar .dropdown-menu.show {
    visibility: visible;
    display: block;
    text-align: center;
  }
}

@media (max-width: 991px) {
  .navbar-toggler span {
    color: #fff;
  }
}

.dropdown-item {
  padding: 9px 23px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  text-transform: uppercase;
}

.dropdown-item:hover {
  background: #1f1f40;
  color: #fff !important;
}

#main-nav.main-nav-black .w-logo {
  display: none;
}

#main-nav.main-nav-black .b-logo {
  display: block;
}

#main-nav.main-nav-black.nav-top .w-logo {
  display: block;
}

#main-nav.main-nav-black.nav-top .b-logo {
  display: none;
}

@media (max-width: 991px) {
  #main-nav.main-nav-black .w-logo {
    display: block;
  }
  #main-nav.main-nav-black .b-logo {
    display: none;
  }
}

.fixed-menu.navbar-nav {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  text-align: left;
  margin-top: 150px;
  box-sizing: border-box;
}

.fixed-menu.navbar-nav li {
  padding: 5px 0px;
}

.fixed-menu.navbar-nav li a {
  color: #fff;
  text-transform: uppercase;
}

.fixed-menu.navbar-nav .dropdown-menu li {
  padding: 0px;
}

.fixed-menu.navbar-nav .dropdown-menu li a {
  color: #000;
}

.logo {
  font-size: 40px;
  font-weight: 700;
  color: #fff;
}

@media (max-width: 575px) {
  .navbar-collapse {
    text-align: center;
    padding: 20px;
  }
  #main-nav .navbar-nav .nav-link {
    padding: 0px;
  }
}

@media (max-width: 400px) {
  .navbar-collapse {
    text-align: center;
    padding: 20px;
  }
  #main-nav .navbar-nav .nav-link {
    padding: 0px;
  }
  .navbar-toggler span {
    color: #fff;
  }
}

@media (max-width: 767px) {
  .navbar-collapse {
    text-align: center;
    padding: 20px;
  }
  #main-nav .navbar-nav .nav-link {
    padding: 0px;
  }
  .navbar-toggler span {
    color: #fff;
  }
}

.content-inner {
  background: url("../images/bg/menu-bg.jpg") no-repeat;
}

.main-wrapper {
  position: relative;
  z-index: 1;
}

.content-inner {
  width: 280px;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 100;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  transition: all .3s ease;
}

.menu-left {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  box-sizing: border-box;
  padding: 55px;
}

.main-content-right {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  box-sizing: border-box;
}

.hide-nav {
  opacity: 0;
  visibility: hidden;
}

.main-nav.main-nav-black .navbar-toggler span {
  color: #000;
}

.main-nav.main-nav-black.hide-nav.nav-top .navbar-toggler span {
  color: #fff;
}

@media (max-width: 991px) {
  .main-nav.main-nav-black.hide-nav .navbar-toggler span {
    color: #fff;
  }
}

.main-nav.main-nav-black .nav-top .navbar-toggler span {
  color: #fff;
}

@media (max-width: 991px) {
  .main-nav.main-nav-black .navbar-toggler span {
    color: #fff;
  }
}

#main-nav.main-nav-black .navbar-brand {
  color: #000;
}

#main-nav.main-nav-black .navbar-brand {
  color: #000 !important;
}

#main-nav.main-nav-black .navbar-nav .nav-link {
  color: #000 !important;
}

#main-nav.main-nav-black.nav-top .navbar-nav .nav-link {
  color: #fff !important;
}

@media (max-width: 991px) {
  #main-nav.main-nav-black .navbar-nav .nav-link {
    color: #fff !important;
  }
}

.menu-socials-links {
  margin-left: 20px;
  bottom: 30px;
  max-width: 250px;
}

.menu-socials-links i {
  width: 40px;
  height: 40px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  display: inline-block;
  text-align: center;
  padding-top: 12px;
  border-radius: 100%;
}

.menu-socials-links i:hover {
  background: #f57479;
  border-color: #f57479;
  color: #fff !important;
}

.meta-share .social-links {
  margin-left: 35px;
  margin-bottom: 130px;
  position: relative;
  transition: .3s all;
  opacity: 0;
}

.meta-share .social-links a {
  display: block;
  padding: 10px 0px;
}

.meta-share .social-links i {
  color: #000;
}

.meta-share .social-links.open {
  right: 0;
  opacity: 1;
}

.share-btn {
  margin-left: 40px;
  bottom: 63px;
  position: absolute;
}

.menu-wrap {
  z-index: 1000;
  position: absolute;
  top: 50%;
  left: 20%;
}

.menu-wrap i {
  color: #000;
}

.menu-left-top {
  margin-left: 45px;
  padding-top: 50px;
}

.content-inner.left {
  width: 100px;
  background: url("../images/bg/menu-bg-2.jpg") no-repeat;
  background-size: cover;
}

@media (max-width: 575px) {
  .hide-nav {
    opacity: 1;
    visibility: visible;
  }
  .content-inner {
    display: none;
  }
}

@media (max-width: 400px) {
  .hide-nav {
    opacity: 1;
    visibility: visible;
  }
  .content-inner {
    display: none;
  }
}

@media (max-width: 767px) {
  .hide-nav {
    opacity: 1;
    visibility: visible;
  }
  .content-inner {
    display: none;
  }
}

@media (max-width: 991px) {
  .hide-nav {
    opacity: 1;
    visibility: visible;
  }
  .content-inner {
    display: none;
  }
}

@media (max-width: 1200px) {
  .hide-nav {
    opacity: 1;
    visibility: visible;
  }
}

.container-box {
  padding: 0px 160px;
}

@media (max-width: 991px) {
  .container-box {
    padding: 15px;
  }
}

.navbar-vertical.navbar-expand-lg.fixed-left {
  left: 0;
  border-width: 0 1px 0 0;
  background: url("../images/bg/menu-bg.jpg") no-repeat;
  background-size: cover;
}

.navbar-vertical.navbar-expand-lg {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  padding: 40px 20px;
  max-width: 250px;
  width: 100%;
}

.navbar-vertical.navbar-expand-lg .dropdown:hover .dropdown-menu {
  visibility: hidden;
  opacity: 0;
}

.navbar-vertical.navbar-expand-lg .dropdown-menu {
  box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.12);
  padding: 15px;
  margin-top: 0;
  display: block;
  visibility: hidden;
  opacity: 0;
  width: 100%;
}

.navbar-vertical.navbar-expand-lg .dropdown-menu.show {
  visibility: visible !important;
  opacity: 1 !important;
  position: static;
}

.navbar-vertical.navbar-expand-lg .navbar-nav {
  flex-direction: column;
}

.navbar-vertical.navbar-expand-lg > [class*="container"] {
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-left: 0;
  padding-right: 0;
}

.navbar-vertical.navbar-expand-lg > .container,
.navbar-vertical.navbar-expand-lg > .container-fluid {
  flex-wrap: nowrap;
}

.navbar-vertical.navbar-expand-lg.fixed-left:not([style*="display: none"]) ~ .main-content {
  margin-left: 250px;
}

.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-nav .active .nav-link {
  color: #fff !important;
  text-transform: uppercase;
}

.navbar-vertical .menu-socials-links a {
  color: rgba(255, 255, 255, 0.8);
}

.navbar-vertical.navbar-expand-lg > [class*="container"] {
  flex-direction: column;
  align-items: stretch;
  min-height: 100%;
  padding-left: 0;
  padding-right: 0;
}

.hide-nav {
  opacity: 0;
}

@media (max-width: 991px) {
  .navbar-vertical.navbar-expand-lg {
    display: none;
  }
  .navbar-vertical.navbar-expand-lg.fixed-left:not([style*="display: none"]) ~ .main-content {
    margin-left: 0px;
  }
  .hide-nav {
    opacity: 1;
  }
}

@media (max-width: 991px) {
  .collapse:not(.show) {
    display: none !important;
  }
}

/* /nav-3 */
/* nav-4 */
.side-nav {
  position: sticky;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100px;
  background: url("../images/bg/menu-bg-2.jpg") no-repeat;
  background-size: cover;
}

.side-nav .toggler {
  position: relative;
  top: 40%;
  left: 30px;
  color: #000;
  padding: 10px;
  text-align: center;
}

.full-nav {
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  position: fixed;
  width: calc(100% - 100px);
  z-index: 10;
  text-align: center;
  left: 100px;
  transform: scale(0);
  transform-origin: left;
  transition: .4s ease;
}

.full-nav.show {
  transform: scale(1);
}

.full-nav .navbar-nav {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.full-nav .navbar-nav .nav-link {
  color: #fff !important;
  font-size: 20px;
}

.full-nav .dropdown-menu {
  display: none;
  opacity: 1;
  visibility: visible;
  margin-top: 0;
  text-align: center;
}

.full-nav .dropdown-menu.show {
  visibility: visible;
  display: block;
}

.banner {
  background: url("../images/banner/banner.jpg") center center no-repeat;
  background-size: cover;
  overflow: hidden;
}

.banner .banner-content {
  padding: 250px 0px 220px 0px;
}

.banner .banner-content h2 {
  font-size: 100px;
  line-height: 100px;
}

.banner .banner-content p {
  font-size: 17px;
}

.banner .banner-img {
  position: absolute;
  content: "";
  bottom: 0px;
  right: 17%;
}

@media (max-width: 1400px) {
  .banner .banner-img {
    right: 10%;
  }
}

@media (max-width: 1200px) {
  .banner .banner-img {
    right: -10%;
  }
}

.banner-2 {
  overflow: hidden;
  padding-top: 50px;
}

.banner-2 .banner-content-2 {
  padding: 180px 0px 120px 0px;
}

@media (max-width: 1200px) {
  .banner-2 .banner-content-2 {
    padding: 150px 0 100px 0;
  }
}

.banner-2 .banner-content-2 h2 {
  font-size: 60px;
  line-height: 70px;
}

@media (max-width: 1200px) {
  .banner-2 .banner-content-2 h2 {
    font-size: 50px;
    line-height: 60px;
  }
}

.banner-2 p {
  color: rgba(255, 255, 255, 0.7);
  padding-right: 55px;
  font-size: 17px;
}

.banner-2 .btn:hover {
  background: #fff;
  color: #000 !important;
}

@media (max-width: 400px) {
  .banner .banner-content h2 {
    font-size: 66px;
    line-height: 86px;
  }
}

@media (max-width: 400px) {
  .banner .banner-content h2 {
    font-size: 66px;
    line-height: 86px;
  }
}

@media (max-width: 991px) {
  .banner .banner-content h2 {
    font-size: 85px;
    line-height: 100px;
  }
  .about-banner {
    background-position: 40% 50%;
  }
}

.service {
  position: relative;
}

.service .service-item {
  padding: 0px 30px;
}

.service .service-item p {
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 0px;
}

.service .service-item i {
  font-size: 60px;
}

.overlay-shape {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.icon-md {
  font-size: 60px;
}

.service-2 {
  background: #ffe5e5;
}

.service-2 .service-item {
  padding: 80px 0px;
}

.service-2 .border-left,
.service-2 .border-right {
  border-color: #e5c5c5 !important;
}

.service-main .border-left {
  border-width: 1px;
  border-style: solid;
  border-image: linear-gradient(to top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)) 1 100%;
}

.service-main .border-left-top {
  border-width: 1px;
  border-style: solid;
  border-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)) 1 100%;
}

.about {
  position: relative;
}

.about:before {
  position: absolute;
  content: "";
  background: url("../images/about/pattern-bg.png") no-repeat;
  width: 90%;
  height: 100%;
  left: 97%;
  top: 23px;
}

.about .about-img {
  position: relative;
}

.about .about-img .video-play {
  position: absolute;
  right: -70px;
  bottom: 70px;
}

@media (max-width: 1200px) {
  .about .about-img .video-play {
    right: 50%;
    bottom: 50%;
    transform: translate(50%, 50%);
  }
}

.about .about-img .video-play a {
  display: inline-block;
  color: #fff;
  background: #934ae4;
  width: 90px;
  height: 90px;
  text-align: center;
  border-radius: 100%;
  padding-top: 30px;
}

.about .about-img .video-play .play-bg {
  position: relative;
  padding-top: 16px;
  width: 130px;
  height: 130px;
  border: 2px solid #fff;
  border-radius: 100%;
  text-align: center;
}

.about .about-img .video-play .play-bg::after {
  position: absolute;
  right: 0px;
  top: 0px;
  border-right: 2px solid #1f1f40;
  width: 100%;
  height: 100%;
  z-index: -1;
  content: "";
  border-radius: 100%;
}

.about .about-img:before {
  position: absolute;
  content: "";
  background: url("../images/about/pattern-bg.png") no-repeat;
  width: 100%;
  height: 100%;
  left: -56px;
  top: 56px;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.bg-1 {
  background: url("../images/bg/about-bg.jpg") no-repeat;
  background-size: cover;
}

.about-banner {
  background: url("../images/bg/about-bg-2.jpg") no-repeat;
  background-size: cover;
}

.service-bg {
  background: url("../images/bg/service-bg.jpg") 0% 50% no-repeat;
  background-size: cover;
}

.portfolio-bg {
  background: url("../images/bg/bg-portfolio.jpg") 0% 40% no-repeat;
  background-size: cover;
}

.blog-bg {
  background: url("../images/bg/blog-bg.jpg") 0% 40% no-repeat;
  background-size: cover;
}

.bg-2 {
  background: url("../images/bg/bg-10.jpg") 53% 5% no-repeat;
}

.header-padding {
  padding: 220px 0px 140px 0px;
}

.text-lg {
  font-size: 50px;
  line-height: 65px;
}

.about-banner {
  padding: 251px 0px 175px 0px;
}

.about-banner .socials-links li a {
  color: #848485;
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.text-black {
  color: #000;
}

.portfolio {
  background: url("../images/bg/bg-2.jpg") no-repeat;
  background-size: cover;
}

.portfolio-block {
  position: relative;
  transition: all .45s ease;
}

.portfolio-block:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(245, 116, 121, 0.95);
  right: 0px;
  opacity: 0;
  visibility: hidden;
  transition: all .45s ease;
}

.portfolio-block:hover {
  box-shadow: 0 1rem 3rem rgba(51, 77, 128, 0.2) !important;
}

.portfolio-block:hover:before {
  opacity: 1;
  visibility: visible;
}

.portfolio-block:hover .card-img-overlay {
  opacity: 1;
  visibility: visible;
}

.portfolio-block .card-img-overlay {
  top: 50%;
  margin-top: -50px;
  opacity: 0;
  visibility: hidden;
  transition: all .45s ease;
}

.portfolio-block .card-img-overlay h5 {
  text-transform: capitalize;
}

.portfolio-2 .btn:focus,
.portfolio-2 .btn:hover,
.portfolio-2 .btn.focus.active,
.portfolio-5 .btn:focus,
.portfolio-5 .btn:hover,
.portfolio-5 .btn.focus.active {
  box-shadow: none !important;
  outline: 0 !important;
}

.portfolio-2 .btn-group .btn,
.portfolio-5 .btn-group .btn {
  border-radius: 0px !important;
  color: #848485;
  padding-left: 0px;
}

.portfolio-2 .btn-group .btn:hover,
.portfolio-5 .btn-group .btn:hover {
  color: #f57479;
}

.portfolio-2 .btn-group:not(:first-child) > .btn,
.portfolio-2 .btn-group > .btn:not(:first-child),
.portfolio-5 .btn-group:not(:first-child) > .btn,
.portfolio-5 .btn-group > .btn:not(:first-child) {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.portfolio-2 .btn.active,
.portfolio-5 .btn.active {
  color: #f57479;
}

.portfolio-5 {
  padding-top: 180px;
}

.pt200 {
  padding-top: 200px;
}

.portfolio-single {
  padding: 180px 0px 100px;
}

.post-single-share {
  background: url("../images/bg/bg-1.jpg") no-repeat;
  background-size: cover;
}

.related-project {
  background: url("../images/bg/bg-11.jpg") no-repeat;
  background-size: cover;
}

.control-next,
.control-prev {
  color: #999;
  margin: 0px 5px;
}

.control-next:hover,
.control-prev:hover {
  color: #f57479;
}

@media (max-width: 575px) {
  .btn-group,
  .btn-group-vertical {
    display: block !important;
  }
}

@media (max-width: 400px) {
  .btn-group,
  .btn-group-vertical {
    display: block !important;
  }
}

/* portfolio item */
.overlay-box {
  position: absolute;
  text-align: center;
  left: 0;
  height: 100%;
  width: 100%;
  top: 0;
}

.overlay-box::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: #1f1f40;
  left: 0;
  top: 0;
  transform: scaleX(0);
  transform-origin: left;
  transition: .2s ease-in;
}

.overlay-box::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: #f57479;
  left: 0;
  top: 0;
  transform: scaleX(0);
  transform-origin: left;
  transition: .2s ease-in;
}

.overlay-box .overlay-inner {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.overlay-box .overlay-inner h5 {
  transform: translateX(-40px);
  opacity: 0;
  transition: .2s ease;
  transition-delay: .0s;
}

.overlay-box .overlay-inner p {
  color: #fff;
  transform: translateX(-16px);
  opacity: 0;
  transition: .2s ease;
  transition-delay: .0s;
}

.inner-box:hover .overlay-box::before {
  transform: scaleX(1);
}

.inner-box:hover .overlay-box::after {
  transform: scaleX(1);
  transition-delay: .3s;
}

.inner-box:hover .overlay-inner h5 {
  transform: translateX(0);
  opacity: 1;
  transition-delay: .4s;
}

.inner-box:hover .overlay-inner p {
  transform: translateX(0);
  opacity: 1;
  transition-delay: .5s;
}

.bg-counter {
  background: url("../images/bg/bg-1.jpg") no-repeat;
  background-size: cover;
  background-position: 50% 67%;
}

.bg-counter-2 {
  background: url("../images/bg/bg-9.jpg") no-repeat;
  background-size: cover;
  background-position: 50% 40%;
}

.counter {
  font-size: 46px;
  font-weight: 700;
}

.counter-content i {
  color: #f57479;
  font-size: 50px;
  line-height: 60px;
}

.counter-content h2 {
  color: #fff;
}

.counter-content p {
  color: #d4dae3;
  font-weight: 600;
}

.img-absolute {
  left: 0px;
  content: "";
  bottom: 0px;
  margin-bottom: -90px;
  width: 70%;
}

.pr-6 {
  padding-right: 4rem !important;
}

.progress-block .progress {
  height: 8px;
  border-radius: 0px;
  margin-bottom: 40px;
  overflow: visible;
}

.progress-block .progress-bar {
  background-color: #934ae4;
  width: 0;
  transition: all 3s ease-in-out;
  top: 0;
  left: 0;
  height: 100%;
  position: relative;
}

.progress-block .progress-bar .skill-number {
  padding: 0px 10px;
  line-height: 22px;
  color: #fff;
  position: absolute;
  top: -36px;
  right: 0px;
  margin-bottom: 10px;
}

.progress-block .progress-bar .skill-number:before {
  position: absolute;
  content: "";
  bottom: -4px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #f57479;
}

.skill-2-bg .progress-bar {
  background-color: #f57479;
}

.skill-2-bg .progress-bar .skill-number {
  background: #934ae4;
}

.skill-2-bg .progress-bar .skill-number:before {
  border-top: 8px solid #934ae4;
}

.bg-testimonial {
  background: url("../images/bg/bg-3.jpg") no-repeat;
  background-size: cover;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0px;
}

.h60 {
  height: 60%;
}

.testimonial .card {
  border: 0px;
  padding: 2.5rem 2rem 2.5rem 2rem;
  border-bottom: 4px solid #f57479;
}

.testimonial .card p {
  font-size: 17px;
  line-height: 30px;
  color: #848484;
}

.testimonial .card .img-block img {
  border-radius: 100%;
  width: 100px;
  height: 100px;
}

.testimonial .card .img-block i {
  width: 40px;
  height: 40px;
  font-size: 13px;
  border-radius: 100%;
  border: 4px solid #fff;
  position: absolute;
  content: "";
  bottom: 0px;
  background: #f57479;
  color: #fff;
  display: inline-block;
  text-align: center;
  line-height: 34px;
  right: 30%;
}

.pattern-bg {
  position: relative;
}

.pattern-bg:before {
  position: absolute;
  content: "";
  background: url("../images/about/pattern-short.png") no-repeat;
  width: 100%;
  height: 100%;
  left: 81%;
  bottom: -200px;
  margin-bottom: -120px;
  z-index: -1;
}

.testimonial-2 {
  background: url("../images/bg/bg-8.jpg") no-repeat;
  background-size: cover;
}

.testimonial-2 p {
  font-size: 20px;
  line-height: 32px;
  font-style: italic;
  color: rgba(255, 255, 255, 0.9);
}

.testimonial-2 .testimonial-content {
  position: relative;
  padding-left: 50px;
}

.testimonial-2 .test-wrap i {
  position: absolute;
  left: 0px;
  content: "";
  top: 0px;
  font-size: 30px;
}

.test-author img {
  width: 80px;
  height: 80px;
  border-radius: 100%;
}

.prev-arrow {
  right: 0px;
}

.next-arrow {
  right: 45px;
}

.slick-arrow {
  position: absolute;
  bottom: 0px;
  z-index: 9;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  border: 0;
  color: #fff;
  background: transparent;
  line-height: 70px;
  font-size: 35px;
  outline: 0;
  -webkit-transition: all .35s ease;
  -moz-transition: all .35s ease;
  -ms-transition: all .35s ease;
  -o-transition: all .35s ease;
  transition: all .35s ease;
  cursor: pointer;
}

.slick-arrow:focus {
  outline: 0;
}

.slick-arrow:hover {
  color: #f57479;
}

.team-item {
  overflow: hidden;
}

.team-overlay-content {
  position: absolute;
  width: 70%;
  left: 0px;
  bottom: 40px;
}

.team-overlay-content h5 {
  transform: translateX(-40px);
  opacity: 0;
  transition: .2s ease;
  transition-delay: .0s;
  position: relative;
  z-index: 1;
}

.team-overlay-content p {
  color: rgba(255, 255, 255, 0.7);
  transform: translateX(-16px);
  opacity: 0;
  transition: .2s ease;
  transition-delay: .0s;
  position: relative;
  z-index: 1;
}

.team-overlay-content::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: #1f1f40;
  left: 0;
  top: 0;
  transform: scaleX(0);
  transform-origin: left;
  transition: .2s ease-in;
}

.team-overlay-content::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: #f57479;
  left: 0;
  top: 0;
  transform: scaleX(0);
  transform-origin: left;
  transition: .2s ease-in;
}

.team-item:hover .team-overlay-content::before {
  transform: scaleX(1);
}

.team-item:hover .team-overlay-content::after {
  transform: scaleX(1);
  transition-delay: .3s;
}

.team-item:hover .team-overlay-content h5 {
  transform: translateX(0);
  opacity: 1;
  transition-delay: .4s;
}

.team-item:hover .team-overlay-content p {
  transform: translateX(0);
  opacity: 1;
  transition-delay: .5s;
}

.latest-blog:before {
  position: absolute;
  content: "";
  background: url("../images/about/pattern-bg.png") no-repeat;
  width: 100%;
  height: 100%;
  left: -63px;
  top: 66%;
}

.latest-blog h4 {
  font-weight: 600;
}

.blog-category {
  position: absolute;
  content: "";
  background: #fff;
  display: inline-block;
  padding: 1px 10px;
  z-index: 1;
  left: 7%;
  top: 9%;
  text-transform: capitalize;
  color: #000;
}

.blog-img.active-state:before {
  content: "";
  background: url("../images/about/pattern-bg.png") no-repeat;
  width: 100%;
  height: 100%;
  left: 73%;
  top: -55px;
  position: absolute;
  z-index: -1;
}

.home-contact {
  background: url("../images/bg/bg-7.jpg") center center no-repeat;
  background-size: cover;
}

.home-contact .form-control {
  background-color: transparent;
  border-radius: 0px;
  border: 0px;
  border-bottom: 1px solid #e6c5c5;
  padding-left: 0px;
  box-shadow: 0px;
  color: #000 !important;
  font-size: 14px;
}

.home-contact .form-control:focus {
  box-shadow: none;
}

.contact-info {
  position: relative;
}

.contact-info i {
  position: absolute;
  content: "";
  left: 0px;
  top: 3px;
  font-size: 35px;
}

.contact-info .info {
  margin-left: 50px;
}

.google-map #map {
  width: 100%;
  height: 450px;
}

.lh-45 {
  line-height: 45px;
}

.pricing {
  background: url("../images/bg/bg-pricing.jpg") 50% 50% no-repeat;
  background-size: cover;
}

.pricing .card {
  -webkit-transition: all .45s ease;
  -moz-transition: all .45s ease;
  -ms-transition: all .45s ease;
  -o-transition: all .45s ease;
  transition: all .45s ease;
}

.pricing .card .card-header {
  background: transparent;
}

.pricing .card .card-header span {
  background: #ffe5e5;
  padding: 2px 23px;
  border-radius: 3px;
}

.pricing .card .card-header p {
  font-size: 18px;
}

.pricing .card.featured {
  background: #934ae4;
}

.pricing .card.featured .card-header {
  border-bottom-color: rgba(255, 255, 255, 0.3);
  background: url("../images/bg/p.png") 40% 0px no-repeat;
}

.pricing .card.featured span {
  background: #a350ff;
  color: #fff;
}

.pricing .card.featured h3 {
  color: #fff;
}

.pricing .card.featured p {
  color: #fff;
}

.pricing .card.featured ul > li {
  color: #fff;
}

.pricing .card.featured .btn-outline-primary {
  background: #f57479;
  color: #fff;
}

.pricing .card.featured .text-muted {
  color: rgba(255, 255, 255, 0.7) !important;
}

.blog-item p {
  font-size: 15px;
}

.blog-item h4 {
  font-weight: 600;
}

.blog-item h4 a {
  color: #000;
}

.form-control {
  padding: 15px;
  border-radius: 0px;
  border-color: #e5e5e5;
  transition: all .3s ease;
}

.form-control:focus {
  border-color: #f57479;
  box-shadow: 6px 4px 9px rgba(51, 77, 128, 0.08);
}

.search-form {
  position: relative;
}

.search-form i {
  position: absolute;
  content: "";
  right: 25px;
  top: 12px;
}

.sidebar-widget h4 {
  font-weight: 600;
}

.cat-list ul li {
  padding-left: 0px;
}

.cat-list ul li a {
  color: #818385;
  font-size: 16px;
}

.cat-list ul li:first-child {
  border-top: 0px;
}

.article-list h5 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
}

.article-list h5 a {
  color: #000;
}

.insta-item {
  width: 32%;
  padding: 10px 5px 0 5px;
  line-height: 1;
  display: inline-block;
}

.tags a {
  padding: 6px 20px;
  margin-right: 10px;
  display: inline-block;
  margin-bottom: 20px;
  color: #818385;
  font-size: 14px;
  line-height: 26px;
  border: 1px solid #e5e5e5;
  text-transform: capitalize;
  border-radius: 3px;
}

.tags a:hover {
  background: #f57479;
  color: #fff !important;
  border-color: #f57479;
}

.tags a.active {
  background: #f57479;
  color: #fff !important;
  border-color: #f57479;
}

.blog-single .blog-category {
  left: 3%;
  top: 6%;
}

.quote {
  background: #f2f4f7;
  border-left: 5px solid #f57479;
  margin: 70px 0px 70px 0px;
}

.quote p {
  font-size: 17px;
  line-height: 30px;
  font-style: italic;
  color: #000;
}

.quote i {
  position: absolute;
  color: #f57479;
  font-size: 87px;
  left: 50%;
  top: 50%;
  margin-top: -50px;
  opacity: .2;
  margin-left: -50px;
}

.post-tags h5, .post-share h5 {
  font-size: 18px;
}

.post-tags li a, .post-share li a {
  color: #848485;
}

.comment-area .btn {
  padding: 2px 20px;
  font-size: 13px;
  line-height: 25px;
  font-weight: 500;
}

@media (max-width: 575px) {
  .media {
    display: block !important;
  }
  .media .media-body {
    margin-top: 10px;
  }
}

@media (max-width: 400px) {
  .media {
    display: block !important;
  }
  .media .media-body {
    margin-top: 10px;
  }
}

.footer-widget {
  color: rgba(255, 255, 255, 0.7);
}

.footer-widget .lh-38 {
  line-height: 38px;
}

.footer-widget ul li {
  color: rgba(255, 255, 255, 0.7);
}

.footer-widget ul li a {
  color: rgba(255, 255, 255, 0.7);
  text-transform: capitalize;
}

.socials-links {
  margin-top: 20px;
}

.socials-links li a {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  text-align: center;
  display: inline-block;
  padding-top: 12px;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.7);
}

.socials-links li a:hover {
  background: #f57479;
  border-color: #f57479;
  color: #fff !important;
}

.socials-links li a.active {
  background: #f57479;
  border-color: #f57479;
  color: #fff !important;
}

.footer-btm .border-top {
  border-top: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.footer-btm span, .footer-btm p {
  color: rgba(255, 255, 255, 0.7);
}

.footer-btm a {
  color: rgba(255, 255, 255, 0.7);
}

.dot {
  width: 7px;
  height: 7px;
  border-radius: 100%;
  background: #f57479;
  display: inline-block;
}

.footer-2 {
  background: url("../images/bg/footer-bg.jpg") no-repeat;
  background-size: cover;
  color: rgba(255, 255, 255, 0.7);
}

.footer-2 a {
  color: rgba(255, 255, 255, 0.7);
}
